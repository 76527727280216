<template>
    <v-main class="main">
        <loading-component :propLoading="loading" />
        <header-component :propHeader="header" />
        <v-carousel
            hide-delimiter-background
            :height="carouselHeightPx"
            :cycle="hierarchy.useCycle()"
            :interval="hierarchy.getInterval()"
            v-model="currentIndex"
            :key="carouselKey"
            @change="changeCarousel"
        >
            <v-carousel-item
                v-for="(page, i) in pageList"
                :reverse-transition="hierarchy.useTransition()"
                :transition="hierarchy.useTransition()"
                :key="i"
                dark
            >
                <div class="carousel-content" :style="bindStyle">
                    <v-container class="ranking-container" v-if="!(page.dispImage === 0)">
                        <sub-header-component
                            :propUser="user"
                            :propImage="subTitle.class"
                            :propSubtitle="subTitle.caption + 'モニタリング中'"
                            :propIsFloor="false"
                            :propRotation="rotation"
                        />
                        <v-row
                            class="card ranking-row"
                            align="center"
                            justify="center"
                            :style="bindStyle"
                        >

                            <v-col
                                v-for="(detail, j) in page.detailList"
                                :key="j"
                            >
                                <ranking-detail-component
                                    :propUser="user"
                                    :propSummary="summary"
                                    :propArea="propArea"
                                    :propDetail="detail"
                                    :propClickable="true"
                                    :propIsSensor="false"
                                    :propGetSearchUnit="getSearchUnit"
                                    :propRotation="rotation"
                                />
                            </v-col>
                        </v-row>
                        <div class="footer">
                            <div>{{ header.dispTimestamp() }} 更新</div>
                            <div v-if="header.dispTimestamp()"> 更新</div>
                            
                        </div>
                    </v-container>
                </div>
                <div
                    v-if="page.dispImage === 0"
                    class="display-image"
                >
                    <img
                        :style="bindStyle"
                        src="../assets/display-text-measure.png"
                    />
                </div>
            </v-carousel-item>
        </v-carousel>
        <fab-component
            :propUser="user"
            :propArea="propArea"
            :propFabPrimary="fabPrimary"
            :propFabRotation="fabRotation"
            :propFabSecondary="fabSecondary"
            :propDownloadable="true"
            :propGetLoading="getLoading"
        />
    </v-main>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinSensor from "../store/mixin.sensor";
import mixinDetail from "../store/mixin.detail";
import LoadingComponent from "../components/LoadingComponent";
import HeaderComponent from "../components/HeaderComponent";
import SubHeaderComponent from "../components/SubHeaderComponent";
import FabComponent from "../components/FabComponent";
import RankingDetailComponent from "../components/RankingDetailComponent";
import models from "../store/models";
import constant from "../store/constant";

const vm = {
    mixins: [mixinApp, mixinSensor, mixinDetail],
    components: {
        LoadingComponent,
        HeaderComponent,
        SubHeaderComponent,
        FabComponent,
        RankingDetailComponent,
    },
    props: {
        propSearchUnit: null,
        propRotationSelectNo: 0,
    },
    data() {
        return {
            searchUnit: null,
            carouselKey: false,
        };
    },
    computed: {
        bindStyle() {
            return {
                "--display-image-size": this.innerWidth * 0.75 + "px",
                "--row-height": this.innerHeightCriteria * 85 + "px",
                "--carousel-content-height": this.getCarouselHeight() + "%",
                "--messege-size": this.detailSize() * 0.55 + "px",
                "--ranking-icon-size": this.detailSize() * 1.2 + "px",
                "--ranking-icon-sub-size": this.detailSize() * 0.6 + "px",
                "--detail-num-size": this.detailSize() + "px",
                "--detail-unit-size": this.detailSize() * 0.8 + "px",
                "--detail-sub-num-size": this.detailSize() * 0.6 + "px",
                "--detail-sub-unit-size": this.detailSize() * 0.6 * 0.8 + "px",
            };
        },
        compSummary() {
            return this.summary || models.createSummary();
        },
        pageList() {
            return this.user
                ? this.compSummary.getRankingPageList(
                      this.isLandscape,
                      this.user
                  )
                : [];
        },
        countDetailPerPage() {
            return this.user
                ? this.compSummary.countRankingDetailPerPage(
                      this.isLandscape,
                      this.user
                  )
                : [];
        },
        
        header() {
            // const icon = this.isSearchUnitRanking()
            //     ? "icon-ranking"
            //     : "icon-fixed";

            return models.createHeader(
                this.hierarchy.hierarchyName,
                "icon-loca",
                this.summary?.getLatestTimestamp
            );
        },
        subTitle() {
            return this.getParamType(this.selectNo).mode;
        },
        rotation() {
            const ret = {};
            ret.func = this.rotationStatus;
            ret.no = this.selectNo;
            return ret;
        },
        fabRotation() {
            return models.createFab("icon-rotation", this.fabRotationStatus);
        },
        fabPrimary() {
            return models.createFab("icon_btn_floormap_3x", this.transitionFloor);
        },
        fabSecondary() {
            const icon = this.isSearchUnitRanking()
                ? "icon_btn_descending_order_3x"
                : "icon-fixed";

            return models.createFab(icon, this.changeSearchUnit);
        },
        selectedIndex() {
            if (!this.currentSensor) {
                return 0;
            }
            const ret = this.pageList.findIndex((val) =>
                val.match(this.currentSensor)
            );

            return ret > 0 ? ret : 0;
        },
    },
    methods: {
        getSearchUnit() {
            return this.searchUnit;
        },
        createSearchCondition() {
            const ret = models.createSearchCondition();

            ret.searchUnit = this.searchUnit;
            ret.userKind = this.user.userKind;
            ret.hierarchyCode = this.hierarchy.hierarchyCode;

            return ret;
        },
        transitionFloor() {
            this.$router.push({
                name: "floor",
                params: {
                    propUser: this.user,
                    propArea: this.propArea,
                    propRotationSelectNo: this.rotation.no,

                },
                query: this.getQuery(),
            });
        },
        isSearchUnitRanking() {
            return this.searchUnit === constant.searchUnitRanking();
        },
        changeSearchUnit() {

            //ソート切替
            if (this.isSearchUnitRanking()) {
                this.searchUnit = constant.searchUnitFloor();
            } else {
                this.searchUnit = constant.searchUnitRanking();
            }
            this.sortDetailList();
        },
        sortDetailList(){
            if (this.isSearchUnitRanking()) {
                this.summary.sortRanking(this.getParamType(this.selectNo).type);
            } else {
                this.summary.sortFloor();
            }
        },
        getCarouselHeight() {
            return this.isIphone ? 115 : 100;
        },
        /** カルーセルの再描画、これを実行すると、グラフのサイズが調整される */
        reRenderCarousel() {
            this.carouselKey = !this.carouselKey;
        },
        changeCarousel() {
            this.reRenderCarousel();
        },
        afterResize() {
            this.reRenderCarousel();
            this.checkIphone();
        },
        rotationStatus(no) {
            this.changeStatus(no);
        },
        detailSize(){
            var parPage = this.countDetailPerPage;
            var fontSize = 0;
            var fontSizeV = 0;
            var innerHeight = this.innerHeightCriteria * 85;
            var innerWidth = this.innerWidth * 0.75;
            if(innerHeight > innerWidth){
                //縦表示
                // 1つ表示
                if(parPage == 1){
                    fontSizeV = 9;
                }
                // 2つ表示
                if(parPage == 2){
                    fontSizeV = 7;
                }
                // 3つ表示
                else if(parPage == 3){
                    fontSizeV = 4.8;
                }
                // 4つ表示
                else if(parPage == 4){
                    fontSizeV = 4.5;
                }
                // 5つ表示
                else if(parPage == 5){
                    fontSizeV = 4.5;
                }
                fontSize = innerWidth * fontSizeV * 0.01;
            }else{
                //横表示
                // 1つ表示
                if(parPage == 1){
                    fontSizeV = 5;
                }
                // 2つ表示
                if(parPage == 2){
                    fontSizeV = 4;
                }
                // 3つ表示
                else if(parPage == 3){
                    fontSizeV = 4;
                }
                // 4つ表示
                else if(parPage == 4){
                    fontSizeV = 3;
                }
                // 5つ表示
                else if(parPage == 5){
                    fontSizeV = 3;
                }
                //縦と横の大小をみて調整
                const fontSizeWidth = innerWidth * fontSizeV * 0.01;
                const fontSizeHeight = innerHeight * 0.08;
                fontSize = fontSizeWidth > fontSizeHeight ? fontSizeHeight :fontSizeWidth;
            }
            return fontSize;
        },
        fabRotationStatus() {
            this.changeStatus("");
            this.sortDetailList();
        }
    },
    created() {
        this.searchUnit = this.propSearchUnit || constant.searchUnitFloor();
        //propRotationSelectNo がない場合はデフォルト
        if (this.propRotationSelectNo != null){
        this.selectNo = this.propRotationSelectNo;
        }

        this.autoUpdating();
        this.currentIndex = this.selectedIndex;
        this.afterResize();
    },
    mounted() {
        this.$nextTick(function () {
            // ビュー全体がレンダリングされた後にのみ実行
            // カルーセルの再描画を少し待機後実行することで、正しく画面表示
            setTimeout(this.reRenderCarousel, 50);
        });
    }
};

export default vm;
</script>

<style scoped>
 .main {
    position: relative;
}

.display-image {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
}

.display-image img {
    width: var(--display-image-size);
    height: auto;
}

/*
.carousel-content {
    height: var(--carousel-content-height);
    display: flex;
    align-items: center;
}

.container {
    width: 90%;
}

.card {
    height: var(--row-height);
}

.col {
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    margin-top: -32px;
} */
</style>