<template>
    <div class="sub-header">

        <div class="sub-header-title">
            <span class="sub-header-title_img">
                <v-icon v-if="isMdi()" class="img" color="white">{{ img }}</v-icon>
                <img v-if="!isMdi()" class="img" :src="src()" />
            </span>
            <span>{{ subtitle }}</span>
        </div>

<!--
        <div class="sub-header-mode">
            <div
                v-for="arr in this.getModeButtons(rotation.no)"
                :key="arr.no"
            >
                <button
                    type="button"
                    :class="{ '-on': arr.onoff, '-off': !arr.onoff}"
                    @click="rotation.func(arr.no)"
                >
                    <div><img class="img" :src="arr.image" /></div>
                    <span>{{ arr.caption }}モード</span>
                </button>
            </div>
        </div>
-->
        <!-- <div class="sub-header-guide usage-guide">
            <span>{{ guide.message }}</span>
            <img class="img" :src="guide.image" />
        </div> -->
        <div class="usage-guide" v-if="isCo2Data()" >
            <img class="img usage-guide_img" src="../assets/icon-face-green.png" />
            <span class="text-color-green" :style="bindStyle">
                {{ strategy.getGuideGreen(isFloor) }}
            </span>
            <img class="img usage-guide_img" src="../assets/icon-face-yellow.png" />
            <span class="text-color-yellow" :style="bindStyle">
                {{ strategy.getGuideYellow() }}
            </span>
            <img
                v-if="strategy.enabled()"
                class="img usage-guide_img"
                src="../assets/icon-face-red.png"
            />
            <span
                v-if="strategy.enabled()"
                class="text-color-red"
                :style="bindStyle"
            >
                {{ strategy.getGuideRed() }}
            </span>
        </div>
        <div class="usage-guide" v-if="isTempData()" >            
            <img class="img usage-guide_img" src="../assets/icon_guide_temp_c.png" />            
        </div>   
        <div class="usage-guide" v-if="isHumiData()" >            
            <img class="img usage-guide_img" src="../assets/icon_guide_humi_c.png" />            
        </div>           
    </div>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinDetail from "../store/mixin.detail";
import constant from "../store/constant";

const vm = {
    mixins: [mixinApp, mixinDetail],
    props: {
        propImage: null,
        propSubtitle: null,
        propIsFloor: false,
        propRotation: null
    },
    computed: {
        bindStyle() {
            return {
                "--text-color-green": constant.green(),
                "--text-color-yellow": constant.yellow(),
                "--text-color-red": constant.red(),
            };
        },
        subtitle() {
            return this.propSubtitle;
        },
        img() {
            return this.propImage;
        },
        isFloor() {
            return this.propIsFloor;
        },
        rotation() {
            return this.propRotation;
        },
        guide() {
            var type = this.getParamType(this.propRotation.no).type;
            var ret = {}
            if (type == "co2c") {

                ret.message = this.hierarchy.lowerYellow + "ppm以下が換気の良好な目安です";
                if (this.strategy.enabled())
                {
                    ret.image = constant.setImage("barometer_co2_3x.png");
                } else {
                    ret.image = constant.setImage("barometer_co2_2x.png");
                }
            } else if (type == "temp") {

                ret.message = this.hierarchy.tempLowerGreen + "℃～" + this.hierarchy.tempUpperGreen + "℃が最適温度の目安です";
                ret.image = constant.setImage("barometer_thermo_3x.png");
            } else if (type == "humi") {

                ret.message = this.hierarchy.humiLowerGreen + "%～" + this.hierarchy.humiUpperGreen + "%が最適湿度の目安です";
                ret.image = constant.setImage("barometer_humidity_3x.png");
            }

            return ret;
        }
    },
    methods: {
        isMdi() {
            return this.img.startsWith("mdi-");
        },
        src() {
            return constant.src(this.img);
        },
        isCo2Data(){
            return this.propRotation.no == "0" // "co2c";
        },
        isTempData(){
            return this.propRotation.no == "1" // "temp";
        },
        isHumiData(){
            return this.propRotation.no == "2" // "humi";
        },           
    },
};

export default vm;
</script>

<style scoped>
/* .sub-header {
    height: 5vh;
    display: flex;
    align-items: center;
} */
/* 
.sub-header .img {
    height: 4vh;
    margin: 1vh;
} */
/* 
.usage-guide {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
} */
/* .usage-guide span{
    height: 40px;
    line-height: 40px;
} */
/* .usage-guide .img {
    margin: 0;
    margin-left: 1vw;
} */
/* 
.usage-guide span {
    text-align: left;
    white-space: nowrap;
} */

.text-color-green {
    color: var(--text-color-green);
}

.text-color-yellow {
    color: var(--text-color-yellow);
}

.text-color-red {
    color: var(--text-color-red);
}
</style>
